// @flow

import React from 'react';
import type { Node } from 'react';
import isArray from 'lodash/isArray';
import isObject from 'lodash/isObject';
import { FormattedMessage } from 'react-intl';
import { Banner as CommonBanner, MediaQuery } from '@riseart/common';
import { Picture, generateSrcForCDN } from 'shared_components/common/artdirection/picture/Picture';
import { generateContentUrl } from 'shared_services/riseart/utils/Utils';

import {
  raScreenXsMax,
  raScreenSm,
  raScreenMdMax,
  raScreenLg,
} from '@riseart/antd-provider/dist/website/variables.less';

type BannerMediaType = {
  small: Object,
  medium: Object,
  large: Object,
};

type MediaTypesType = {
  small: string,
  medium: string,
  large: string,
};

type Props = {
  className?: string,
  media: ?BannerMediaType,
  children?: any,
  artDirectionKey: string,
  artDirectionType: string,
  altTitle: string,
  lazyload?: ?Object,
  size: ?string,
  gradient?: boolean,
  imgProps?: Object,
};

/**
 * getMediaListByHierarchy
 *
 * @param {Array<Object> | Object} media
 */
function getMediaListByHierarchy(media?: Array<Object> | Object) {
  // Flat image structure
  if (isArray(media)) {
    return media;
  }

  // Hierarchical image structure with children
  if (media && media.children && isArray(media.children) && media.children) {
    return media.children;
  }

  if (media && isObject(media) && media.small && media.medium && media.large) {
    const TYPE_TO_POSTER_WIDTH = {
      small: 600,
      medium: 800,
      large: 1200,
    };
    return Object.keys(media).map((type: string): Array<Object> => {
      const { file, poster } = media ? media[type] : {};

      return {
        media: file,
        ...(poster
          ? {
              mediaProps: {
                poster: generateSrcForCDN({
                  ...poster,
                  width: TYPE_TO_POSTER_WIDTH[type] || TYPE_TO_POSTER_WIDTH.medium,
                  height: 'AUTO',
                }),
              },
            }
          : null),
        type,
      };
    });
  }

  return [];
}

/**
 * getBannerMedia
 *
 * @param {Array<Object> | Object} media
 * @param {Array<Object>} fallbackImages
 * @param {MediaTypesType} types
 */
export function getBannerMedia(
  media?: Array<Object> | Object,
  fallbackImages: Array<Object>,
  types: MediaTypesType = { small: 'small', medium: 'medium', large: 'large' },
  mediaStructureNormalizer: Function = getMediaListByHierarchy,
): BannerMediaType {
  const mediaList = mediaStructureNormalizer(media);

  return Object.keys(types).reduce((accumulator: Object, key: string): Object => {
    const type = types[key];
    const foundMediaByType = mediaList.filter((item) => item.type === type)[0];

    accumulator[key] = foundMediaByType || fallbackImages.filter((item) => item.type === type)[0];

    return accumulator;
  }, {});
}

/**
 * renderMediaComponentByType
 *
 * @param {Object | Array<Object>} media
 * @param {string} type
 * @param {Object} restProps
 * @returns {?Node}
 */
const renderMediaComponentByType = (
  media: Object | Array<Object>,
  type: string,
  { artDirectionType, artDirectionKey, altTitle, lazyload, imgProps, mediaProps }: Object,
): ?Node => {
  // Image
  if (type === 'image') {
    return (
      <Picture
        type={artDirectionType}
        artDirectionKey={artDirectionKey}
        image={media}
        alt={altTitle}
        lazyload={lazyload}
        {...(imgProps || mediaProps
          ? { imgProps: { ...(imgProps || null), ...(mediaProps || null) } }
          : null)}
      />
    );
  }

  // Video (single source or list of sources in different formats)
  if (type === 'video') {
    return (
      <video autoPlay loop muted playsInline {...(mediaProps || null)}>
        {isArray(media) ? (
          media.map(({ id, key, extension, mimeType, url }) => (
            <source key={id} src={url || generateContentUrl(key, extension)} type={mimeType} />
          ))
        ) : (
          <source
            key={media.id}
            src={media.url || generateContentUrl(media.key, media.extension)}
            type={media.mimeType}
          />
        )}
        <span>
          <FormattedMessage id="components.media.noVIdeoSupport">
            {(text: string) => text}
          </FormattedMessage>
        </span>
      </video>
    );
  }

  return null;
};

/**
 * renderMedia
 *
 * @param {Object | Array<Object>} media
 * @param {Object} props
 * @returns {?Node}
 */
const renderMedia = (media: Object | Array<Object>, props: Object): ?Node => {
  const DEFAULT_MEDIA_TYPE = 'image';

  if (!media) {
    return null;
  }

  // Media is an array of video sources
  if (isArray(media)) {
    const isVideo = media.every(
      ({ mimeType }) => mimeType && mimeType.split('/').shift() === 'video',
    );

    return isVideo
      ? renderMediaComponentByType(media, 'video', props)
      : media.map((item) => renderMediaComponentByType(item, item.mimeType.split('/')[0], props));
  }

  // Media is an object of one source
  return renderMediaComponentByType(
    media,
    (media.mimeType && media.mimeType.split('/')[0]) || DEFAULT_MEDIA_TYPE,
    props,
  );
};

/**
 * Banner
 *
 * @param {Props} props
 * @returns {Node}
 */
export const Banner = ({
  className,
  size = null,
  gradient = false,
  children,
  media = null,
  ...restProps
}: Props): Node => (
  <MediaQuery maxWidth={raScreenXsMax}>
    {(isPhone) => (
      <MediaQuery minWidth={raScreenSm} maxWidth={raScreenMdMax}>
        {(isTablet) => (
          <MediaQuery minWidth={raScreenLg}>
            {(isDesktop) => {
              const mediaByMediaQuery =
                media &&
                media[(isPhone && 'small') || (isTablet && 'medium') || (isDesktop && 'large')];

              return (
                <CommonBanner
                  className={className}
                  size={size}
                  gradient={gradient}
                  media={renderMedia(
                    mediaByMediaQuery && (mediaByMediaQuery.media || mediaByMediaQuery),
                    {
                      ...restProps,
                      ...(mediaByMediaQuery && mediaByMediaQuery.mediaProps
                        ? { mediaProps: mediaByMediaQuery.mediaProps }
                        : null),
                    },
                  )}
                >
                  {children}
                </CommonBanner>
              );
            }}
          </MediaQuery>
        )}
      </MediaQuery>
    )}
  </MediaQuery>
);
